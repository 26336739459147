<template>
  <div class="login-bg justify-content-center align-items-center">
    <div class="auth-v2">
      <b-row
        v-if="true"
        align-h="center"
        align-v="center"
        class="auth-inner m-0 justify-content-center"
      >
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="login-bg d-flex justify-content-center align-items-center"
        >
          <b-card
            class="justify-content-center rounded-lg"
            no-body
            style="height: 38rem; width: 70rem"
          >
            <b-row align-h="between" class="mx-0 align-items-center">
              <b-col
                class="justify-content-center align-items-center pr-0 text-center"
                sm="12"
                md="6"
                lg="6"
              >
                <b-avatar
                  class="bg-light-secondary"
                  variant="transparent"
                  rounded="lg"
                  size="30rem"
                >
                  <b-carousel
                    id="carousel-interval"
                    indicators
                    :interval="3000"
                    class="h-100 w-100 justify-content-center align-items-center"
                  >
                    <b-carousel-slide
                      v-for="(item, i) in assets.images"
                      :key="i"
                    >
                      <template v-slot:img>
                        <b-img
                          class="h-100 w-100"
                          width="100%"
                          height="100%"
                          :src="item"
                        ></b-img>
                      </template>
                    </b-carousel-slide>
                  </b-carousel>
                  <!-- <b-img src="msg_02.jpg" fluid></b-img> -->
                </b-avatar>
              </b-col>
              <b-col
                v-if="!is_login_success"
                class="pl-0"
                sm="12"
                md="6"
                lg="6"
              >
                <b-row>
                  <b-col class="pl-0" sm="12" md="12" lg="12">
                    <b-card-body
                      style="height: 28rem; overflow-y: auto"
                      class="mx-3"
                    >
                      <center>
                        <b-img
                          style="max-height: 2rem"
                          :src="logo"
                          fluid
                        ></b-img>
                      </center>
                      <validation-observer ref="loginForm" #default="{}">
                        <b-form
                          class="auth-login-form mt-2"
                          @submit.prevent="login"
                        >
                          <!-- username -->
                          <b-form-group class="mb-2">
                            <label for="login-username">{{
                              lang("t_username")
                            }}</label>
                            <validation-provider
                              #default="{ errors }"
                              name="username"
                              vid="username"
                              rules="required"
                            >
                              <b-form-input
                                id="login-username"
                                v-model="username"
                                :state="errors.length > 0 ? false : null"
                                name="login-username"
                                :placeholder="lang('t_userName')"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                          <!-- forgot password -->
                          <b-form-group>
                            <div class="d-flex justify-content-between">
                              <label for="login-password">{{
                                lang("t_password")
                              }}</label>
                            </div>
                            <validation-provider
                              #default="{ errors }"
                              name="Password"
                              vid="password"
                              rules="required"
                            >
                              <b-input-group
                                class="input-group-merge"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                              >
                                <b-form-input
                                  id="login-password"
                                  v-model="password"
                                  :state="errors.length > 0 ? false : null"
                                  class="form-control-merge"
                                  :type="passwordFieldType"
                                  name="login-password"
                                  :placeholder="lang('t_password')"
                                />
                                <b-input-group-append is-text>
                                  <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                                  />
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                          <!-- checkbox -->
                          <b-form-group class="mb-3">
                            <b-row class="mx-0">
                              <b-form-checkbox
                                id="remember-me"
                                v-model="status"
                                name="checkbox-1"
                              >
                                {{ lang("t_rememberMe") }}
                              </b-form-checkbox>
                              <b-link class="ml-auto" @click="forgot_password">
                                <small>{{ lang("t_forgotPassword") }}</small>
                              </b-link>
                            </b-row>
                          </b-form-group>

                          <!-- submit buttons -->
                          <b-button type="submit" variant="primary" block>
                            {{ lang("t_login") }}
                          </b-button>
                        </b-form>
                      </validation-observer>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-else class="pl-0" sm="12" md="6" lg="6">
                <b-row>
                  <b-col class="pl-0" sm="12" md="12" lg="12">
                    <b-card-body
                      style="height: 28rem; overflow-y: auto"
                      class="mx-3"
                    >
                      <center>
                        <b-img
                          style="max-height: 2rem"
                          :src="logo"
                          fluid
                        ></b-img>
                      </center>
                      <!-- <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
                      {{  }}
                    </b-card-title> -->
                      <b-row class="mt-3">
                        <b-col>
                          <span class="font-weight-bold font-medium-1">{{
                            `${lang("t_hello")} `
                          }}</span>
                          <span class="font-weight-bold">{{
                            `${username.split(".")[0]},`
                          }}</span>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col>
                          <span class="d-block">
                            {{ lang("t_PleaseSelectProjetAndQueues") }}
                          </span>
                        </b-col>
                      </b-row>

                      <!-- username -->
                      <b-form-group class="mb-2">
                        <v-select
                          appendToBody
                          @input="selected_queues = []"
                          v-model="selected_project"
                          :options="projects"
                          :placeholder="lang('t_projects')"
                          label="display_name"
                          :reduce="(val) => val.internal_name"
                          :map-keydown="preventBackspace"
                        >
                        </v-select>
                      </b-form-group>

                      <!-- forgot password -->
                      <b-form-group class="mb-3">
                        <v-select
                          appendToBody
                          v-model="selected_queues"
                          :options="
                            queues.filter((e) => e.project == selected_project)
                          "
                          :placeholder="lang('t_queues')"
                          label="display_name"
                          :reduce="(val) => val.internal_name"
                          multiple
                          @option:deselected="deselect_queue"
                          :selectable="() => static_queues.length == 0"
                          :map-keydown="preventBackspace"
                        >
                          <template #search="{ events, attributes }">
                            <input
                              class="vs__search"
                              v-on="events"
                              v-bind="attributes"
                              maxlength="2"
                            />
                          </template>

                          <template #list-header>
                            <div
                              v-if="static_queues.length == 0"
                              class="my-50 mx-50"
                            >
                              <b-button
                                class="w-50"
                                size="sm"
                                variant="flat-secondary"
                                @click="
                                  () => {
                                    selected_queues = queues
                                      .filter(
                                        (e) => e.project == selected_project
                                      )
                                      .map((e) => e.internal_name);
                                  }
                                "
                              >
                                <feather-icon
                                  size="14"
                                  icon="CheckCircleIcon"
                                />
                                {{ lang("t_selectAll") }}
                              </b-button>
                              <b-button
                                class="w-50"
                                size="sm"
                                variant="flat-secondary"
                                @click="
                                  () => {
                                    selected_queues = [];
                                  }
                                "
                              >
                                <feather-icon size="14" icon="XOctagonIcon" />
                                {{ lang("t_clear") }}
                              </b-button>
                            </div>
                          </template>
                        </v-select>
                      </b-form-group>

                      <!-- submit buttons -->
                      <b-button
                        @click="start"
                        v-if="selected_queues.length > 0"
                        variant="primary"
                        block
                      >
                        {{ lang("t_start") }}
                      </b-button>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else class="auth-inner m-0">
        <!-- Brand logo-->

        <!-- /Brand logo-->

        <!-- Left Text-->
        <!-- <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            width="720"
            height="720"
            fluid
            src="images/login/test2.gif"
            alt="Login V2"
          />
        </div>
      </b-col> -->
        <!-- /Left Text-->

        <!-- Login-->
        <b-col sm="12" md="12" lg="12" class="login-bg"></b-col>

        <b-col
          v-if="!is_login_success"
          lg="4"
          offset-lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col sm="8" md="6" lg="12">
            <b-card-text class="mb-1 m font-weight-bold" title-tag="h2">
              <center>
                <b-img
                  class="mb-3"
                  :style="{
                    filter: skin == 'dark' ? 'brightness(0) invert(1)' : '',
                  }"
                  width="240px"
                  height="90px"
                  src="v_main_logo.png"
                  alt="logo"
                />
              </center>
            </b-card-text>
            <b-card-title
              class="mb-1 font-weight-bold center-text"
              title-tag="h2"
            >
              {{ lang("t_welcome") }}
            </b-card-title>
            <b-card-text class="mb-2 center-text">
              {{ lang("t_pleaseSingIn") }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginForm" #default="{}">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- username -->
                <b-form-group class="mb-2">
                  <label for="login-username">{{ lang("t_username") }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      :placeholder="lang('t_userName')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ lang("t_password") }}</label>
                    <b-link @click="forgot_password">
                      <small>{{ lang("t_forgotPassword") }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        :placeholder="lang('t_password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group class="mb-3">
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{ lang("t_rememberMe") }}
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button type="submit" variant="primary" block>
                  {{ lang("t_login") }}
                </b-button>
              </b-form>
            </validation-observer>
            <!-- <div>
            <center>
              <b-img
                style="width: 50%; height: 50%"
                class="mt-5"
                src="tt_logo.png"
                alt="logo"
              />
            </center>
          </div> -->

            <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

            <!-- divider -->
            <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

            <!-- social buttons -->
            <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
          </b-col>
        </b-col>
        <b-col
          v-else
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          offset-lg="4"
        >
          <b-col sm="8" md="6" lg="12">
            <b-card-text class="mb-1 font-weight-bold" title-tag="h2">
              <center>
                <b-img
                  class="mb-3"
                  :style="{
                    filter: skin == 'dark' ? 'brightness(0) invert(1)' : '',
                  }"
                  width="240px"
                  height="90px"
                  src="v_main_logo.png"
                  alt="logo"
                />
              </center>
            </b-card-text>
            <b-row>
              <b-col>
                <span class="font-weight-bold">{{
                  `${lang("t_hello")} `
                }}</span>
                <span class="font-weight-bold">{{
                  `${username.split(".")[0]},`
                }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="d-block">
                  {{ lang("t_PleaseSelectProjetAndQueues") }}
                </span>
              </b-col>
            </b-row>

            <!-- username -->
            <b-form-group class="mb-2">
              <v-select
                @input="selected_queues = []"
                v-model="selected_project"
                :options="projects"
                :placeholder="lang('t_projects')"
                label="display_name"
                :reduce="(val) => val.internal_name"
              >
              </v-select>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group class="mb-3">
              <v-select
                v-model="selected_queues"
                :options="queues.filter((e) => e.project == selected_project)"
                :placeholder="lang('t_queues')"
                label="display_name"
                :reduce="(val) => val.internal_name"
                multiple
                @option:deselected="deselect_queue"
                :selectable="() => static_queues.length == 0"
              >
                <template #search="{ events, attributes }">
                  <input
                    class="vs__search"
                    v-on="events"
                    v-bind="attributes"
                    maxlength="2"
                  />
                </template>
                <template #list-header>
                  <div class="w-100 mb-50">
                    <b-button
                      :disabled="static_queues.length > 0"
                      class="w-50"
                      size="sm"
                      variant="outline-primary"
                      @click="
                        () => {
                          selected_queues = queues
                            .filter((e) => e.project == selected_project)
                            .map((e) => e.internal_name);
                        }
                      "
                    >
                      <feather-icon size="14" icon="CheckCircleIcon" />
                      {{ lang("t_selectAll") }}
                    </b-button>
                    <b-button
                      :disabled="static_queues.length > 0"
                      class="w-50"
                      size="sm"
                      variant="outline-danger"
                      @click="
                        () => {
                          selected_queues = [];
                        }
                      "
                    >
                      <feather-icon size="14" icon="XOctagonIcon" />
                      {{ lang("t_clear") }}
                    </b-button>
                  </div>
                </template>
              </v-select>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ lang("t_rememberMe") }}
                </b-form-checkbox>
              </b-form-group> -->

            <!-- submit buttons -->
            <b-button
              @click="start"
              v-if="selected_queues.length > 0"
              variant="primary"
              block
            >
              {{ lang("t_start") }}
            </b-button>

            <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

            <!-- divider -->
            <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

            <!-- social buttons -->
            <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
          </b-col>
        </b-col>
      </b-row>
    </div>
    <center
      class="position-fixed text-center pb-1"
      style="bottom: 0; left: 50%; transform: translateX(-50%)"
    >
      <b-img
        class="pr-75"
        style="filter: brightness(0) invert(1)"
        width="auto"
        height="36"
        :src="assets.logo"
      ></b-img>
      <b-img
        class="pl-75"
        :class="assets.logo != '' ? 'border-left' : ''"
        style="filter: brightness(0) invert(1)"
        width="36"
        :src="login_footer_img"
        fluid
      ></b-img>
      <span class="text-light ml-75">
        <b-link
          :href="login_footer_url"
          target="_blank"
          class="ml-auto text-light"
        >
          {{ login_footer_web_title }}
        </b-link>
        {{ login_footer_desc }}</span
      >
    </center>
    <b-modal
      @ok="login"
      v-model="modal_2fa"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      ok-only
      :ok-title="lang('t_verify')"
      hide-header
      hide-footer
    >
      <!-- <b-form-group :label="lang('t_verificationCode')">
        <b-form-input
          v-model="verification_code"
          :placeholder="lang('t_verificationCodeDesc')"
          trim
        ></b-form-input>
      </b-form-group> -->
      <div>
        <!-- <b-form-group :label="lang('t_verificationCode')"> -->
        <div class="align-items-center justify-content-center text-center">
          <b-img fluid class="mt-1" center width="96" src="2fa.png"></b-img>
          <span class="d-block font-medium-5 font-weight-bolder my-1">{{
            lang("t_twoFactorAuthentication")
          }}</span>
          <span
            v-if="this.type_2fa == 'SMS'"
            class="d-block font-medium-1 text-wrap"
          >
            <strong>{{ info_2fa }}</strong> {{ lang("t_sentToPhoneNumber") }}
          </span>
          <span v-else class="d-block font-medium-1 text-wrap">
            <strong>{{ info_2fa }}</strong> {{ lang("t_sentToEmailAdress") }}
          </span>
          <span class="d-block font-medium-1 text-wrap">{{
            lang("t_verifyYourIdentity")
          }}</span>
        </div>
        <div
          @paste.prevent="handlePaste"
          class="d-flex justify-content-center my-1"
        >
          <b-form-input
            v-for="(item, index) in verificationCodes"
            :key="index"
            v-model="verificationCodes[index]"
            :ref="`input-${index}`"
            @input="handleInput(index)"
            @keydown.backspace="handleBackspace(index)"
            @keydown.enter="login"
            :placeholder="''"
            maxlength="1"
            trim
            class="verification-input bg-light-primary mx-25 border-0 font-medium-5 text-center font-weight-bold"
            type="text"
          ></b-form-input>
        </div>
        <div class="d-block justify-content-center text-center mx-4">
          <span class="d-block font-medium-1 text-muted"
            >{{ lang("t_remainingTime") + ":" }}
            <strong>{{ timeout_2fa + lang("t_sec") }}</strong></span
          >
          <b-button block class="mt-3 mb-1" @click="login" variant="primary">{{
            lang("t_verify")
          }}</b-button>
        </div>
        <div class="d-block justify-content-center text-center">
          <span class="d-block font-small-4 text-muted"
            >{{ lang("t_donthaveAccess") }}
          </span>
          <span class="d-block mb-1 font-small-4 text-muted"
            >{{ lang("t_verificationCode") }}
            <b-link @click="ResendCode('MAIL')" v-if="type_2fa == 'SMS'">{{
              lang("t_sendAsMail")
            }}</b-link>
            <b-link @click="ResendCode('SMS')" v-else>{{
              lang("t_sendAsSms")
            }}</b-link>
          </span>
        </div>

        <!-- </b-form-group> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BModal,
  BAvatar, BCardHeader, BCardBody, BCarousel, BCarouselSlide,
  BCard, BListGroup, BListGroupItem, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useAppConfig from '@core/app-config/useAppConfig'
import vSelect from 'vue-select'
import { computed } from '@vue/composition-api'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BModal,
    BCarousel, BCarouselSlide,
    BCardHeader, BCardBody,
    vSelect,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      logo: globalThis.env.LOGIN_LOGO,
      login_footer_img: globalThis.env.LOGIN_FOOTER_IMG,
      login_footer_web_title: globalThis.env.LOGIN_FOOTER_WEB_TITLE,
      login_footer_url: globalThis.env.LOGIN_FOOTER_URL,
      login_footer_desc: globalThis.env.LOGIN_FOOTER_DESC,
      status: true,
      modal_2fa: false,
      timeout_2fa: 180,
      info_2fa: '',
      type_2fa: '',
      interval_2fa: null,
      password: '',
      username: '',
      verificationCodes: ['', '', '', '', '', ''],
      sideImg: '@/assets/images/logo/v_main_logo.png',
      is_login_success: false,
      projects: [],
      queues: [],
      selected_project: '',
      selected_queues: [],
      assets: {
        logo: '',
        images: [

        ]
      },
      // validation rules
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')


    return {
      skin, isDark
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      return this.sideImg
      // }
      return this.sideImg
    },
  },
  methods: {
    async ResendCode(type) {
      let _payload = {
        username: this.username,
        password: this.password,
        type
      };

      var response = (
        await this.$http_in.post(`Auth/v1/ResendCode`, _payload)
      ).data;
      if (response) {
        this.$bvToast.toast(`${globalThis._lang('t_verificationCodeHasBeenResend')}`, {
          title: `${globalThis._lang("t_notification")}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'success',
        })
      } else {
        this.$bvToast.toast(`${globalThis._lang('t_failed')}`, {
          title: `${globalThis._lang("t_notification")}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'danger',
        })
      }
    },
    formatTime(seconds) {
      seconds = Number(seconds);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    handlePaste(event) {
      const paste = (event.clipboardData || window.clipboardData).getData('text').trim();
      const characters = paste.slice(0, this.verificationCodes.length).split('');
      for (let i = 0; i < this.verificationCodes.length; i++) {
        this.verificationCodes[i] = characters[i] || '';
      }
      this.$nextTick(() => {
        const lastInputIndex = characters.length < this.verificationCodes.length ? characters.length - 1 : this.verificationCodes.length - 1;
        this.$refs[`input-${lastInputIndex}`][0].focus();
      });
      this.verificationCodes = [...this.verificationCodes];
    },

    handleInput(index) {
      if (index < this.verificationCodes.length - 1) {
        this.$nextTick(() => {
          this.$refs[`input-${index + 1}`][0].focus();
        });
      }
    },
    handleBackspace(index) {
      // if (this.verificationCodes[index] === '' && index > 0) {
      this.verificationCodes[index] = '';
      this.$nextTick(() => {
        this.$refs[`input-${index - 1}`][0].focus();
      });
      // }
    },
    // moveFocus(index) {
    //   console.log("index", index);
    //   const nextInput = this.$refs[`input-${index + 1}`];
    //   console.log("nextInput", nextInput);

    //   if (nextInput) {
    //     nextInput[0].focus();
    //   }
    // },
    preventBackspace(event) {
      event.preventDefault();
    },
    async getCustomization(url) {
      var response = (
        await this.$http_in.post(`/reseller/v1/Assets`, { URL: url })
      ).data;
      if (response.Logo) {
        this.assets.logo = response.Logo.Type + response.Logo.Data;
      } else {
        this.assets.logo = '';
      }

      if (response && response.Images && response.Images.length > 0) {
        this.assets.images = [];
        this.assets.images = response.Images.map(e => { return e.Type + e.Data });
      } else {
        this.assets.images = [
          '/login1.jpg',
          '/login2.jpg',
          '/login3.jpg',
          '/login4.jpg'
        ];
      }
      console.log("c_respıonse", response);
    },
    async getRegion() {
      if (!window.localStorage.getItem('language')) {
        var response = (
          await this.$http_in.get(`Auth/v1/Region`)
        ).data;
        if (response == "TR") {
          globalThis.selected_lang = 'tr';
          globalThis.selectLang = selectLang;
          globalThis._lang = globalThis._l(window.localStorage.getItem('language') ?? "tr");
          window.localStorage.setItem('language', "tr");
          window.location.reload();
        } else {
          globalThis.selected_lang = 'en';
          globalThis.selectLang = selectLang;
          globalThis._lang = _l(window.localStorage.getItem('language') ?? "en");
          window.localStorage.setItem('language', "en");
          window.location.reload();
        }
        return;
      }
      globalThis.selected_lang = 'en';
      globalThis.selectLang = selectLang;
      globalThis._lang = _l(window.localStorage.getItem('language') ?? "en");
    },
    async forgot_password() {
      if (this.username.trim().length > 0) {

        let salty_username = btoa(this.username.trim()).split('').reverse().join('');
        var response = (
          await this.$http_in.get(`sch/v1/ForgotPassword/${salty_username}`,)
        ).data;

        this.$bvToast.toast(`${globalThis._lang(response.message)}`, {
          title: `${globalThis._lang("t_notification")}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: response.acknowledge ? 'success' : 'danger',
        })
      }

    },
    deselect_queue(item) {
      if (this.static_queues.length > 0) {
        this.selected_queues = this.static_queues;

      }
    },
    async start() {
      var response = (
        await this.$http_in.post(`Auth/v1/AddProjectAndQueuePermissions`, {
          project_name: this.selected_project,
          queues: this.selected_queues,
        })
      ).data;
      // console.log("privileges", response.permissions);
      // GET PRIVILEGES
      globalThis.register_info = response.register_info;
      globalThis.user = response.user;
      console.log("response.user", response.user);

      var _response = (
        await this.$http_in.get(`agent/v1/UserInfoRefresh`)
      ).data;

      globalThis.user = {
        ...globalThis.user,
        ..._response
      };
      this.$http_in.defaults.headers.common['text-channel-authorization'] = globalThis.user.text_channel_token;
      this.$http_in.defaults.headers.common['text-channel-user'] = globalThis.user.text_channel_user_id;

      console.log(_response);
      globalThis.permissions = {};
      for (const item of response.permissions) {
        if (globalThis.permissions[item.split(':')[0]] == undefined) {
          globalThis.permissions[item.split(':')[0]] = [item.split(':')[1]];
        }
        else {
          globalThis.permissions[item.split(':')[0]].push(item.split(':')[1])
        }
      }

      if (this.status) {
        let _userQueues = JSON.parse(localStorage.getItem('userQueues'));
        if (![undefined, null].includes(_userQueues)) {
          let _data = _userQueues.find(e => e.username == this.username);
          if (![undefined, null].includes(_data)) {
            _data.project = this.selected_project;
            _data.queues = this.selected_queues;
          }
          else {
            _userQueues.push({
              username: this.username,
              project: this.selected_project,
              queues: this.selected_queues,
            })
          }
        } else {
          _userQueues = [];
          _userQueues.push({
            username: this.username,
            project: this.selected_project,
            queues: this.selected_queues,
          })
        }

        globalThis.projects = this.projects;
        localStorage.setItem('userQueues', JSON.stringify(_userQueues))
      }

      // this.register(response.register_info);

      this.$router.replace('/agent_dashboard');

    },

    select_queue(queue) {
      if (this.selected_queues.includes(queue.internal_name)) {
        this.selected_queues = this.selected_queues.filter(e => e != queue.internal_name)
      }
      else {
        this.selected_queues.push(queue.internal_name);
      }
    },

    async get_user_project_and_queues() {
      var response = (
        await this.$http_in.get(`agent/v1/UserProject`)
      ).data;

      this.projects = response.projects;
      this.queues = response.queues;

      this.static_queues = [];
      for (const item of response.queues) {
        if (item.static_users.includes(this.username)) {
          localStorage.removeItem('userQueues');
          this.static_queues.push(item.internal_name);
          this.selected_project = item.project;
          globalThis.static_user = true;
        }
      }
      // console.log("this.static_queues", this.static_queues);
      if (this.static_queues.length > 0) {
        this.selected_queues = this.static_queues;

      }

    },
    async login() {

      var success = true; //await this.$refs.loginForm.validate();
      if (success) {
        let _payload = {
          username: this.username,
          password: this.password
        };
        console.log('this.modal_2fa', this.modal_2fa)
        if (this.modal_2fa == true) {
          _payload["verification_code"] = this.verificationCodes.join('');
        }
        var response = (
          await this.$http_in.post(`Auth/v1/Login`, _payload)
        ).data;
        this.modal_2fa = false;
        this.verificationCodes = ['', '', '', '', '', ''];
        if (this.interval_2fa) {
          clearInterval(this.interval_2fa);
          this.interval_2fa = null;

        }

        // console.log("resp", response);
        if (response.token == "Username or password is invalid.") {
          this.$bvToast.toast(`${globalThis._lang("t_usernameOrPasswordIsInvalid")}`, {
            title: `${globalThis._lang("t_warning")}`,
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
            variant: 'warning',
          });
          return;
        }
        if (response.token == "Tenant state is passive") {
          this.$bvToast.toast(`Tenant state is passive`, {
            title: `${globalThis._lang("t_warning")}`,
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
            variant: 'warning',
          })
          return;
        }
        if (response.token == "Account is locked") {
          this.$bvToast.toast(`Account is locked`, {
            title: `${globalThis._lang("t_warning")}`,
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
            variant: 'warning',
          })
          return;
        }
        if (response.token == "Outside active time") {
          this.$bvToast.toast(`Outside active time`, {
            title: `${globalThis._lang("t_warning")}`,
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
            variant: 'warning',
          })
          return;
        }
        if (response.token == "Verification code is invalid") {
          this.$bvToast.toast(`Verification code is invalid`, {
            title: `${globalThis._lang("t_warning")}`,
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
            variant: 'warning',
          })
          return;
        }
        if (response.token == "Verification code send") {

          this.type_2fa = response.type;
          this.info_2fa = this.type_2fa == 'SMS' ? response.phone : response.email;
          this.timeout_2fa = response.timeout;
          this.interval_2fa = setInterval(() => {
            this.timeout_2fa--;
            if (this.timeout_2fa == 0) {
              this.login();
            }
          }, 1000);
          this.modal_2fa = true;

          return;
        }
        console.log("AAA", response);
        globalThis.username = this.username;
        globalThis.qm_token = response.token;
        this.$http_in.defaults.headers.common['authorization'] = response.token;
        this.$http_in.defaults.headers.common['username'] = this.username;


        this.is_login_success = true;
        const userData = { password: this.password, username: this.username }

        if (this.status) {
          localStorage.setItem('userData', JSON.stringify(userData))
        }
        await this.get_user_project_and_queues();

        let userQueues = JSON.parse(localStorage.getItem('userQueues'));
        if (![undefined, null].includes(userQueues)) {
          let _data = userQueues.find(e => e.username == this.username);
          if (![undefined, null].includes(_data)) {
            this.selected_project = _data.project;
            for (const row of this.queues) {
              if (_data.queues.includes(row.internal_name)) {
                this.selected_queues.push(row.internal_name);

              }
            }
          }
        }


        // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
        // .then(() => {

        this.$bvToast.toast(`${globalThis._lang("t_successfullyLoggedIn")}`, {
          title: `${globalThis._lang("t_welcome")} ${userData.username}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'success',
        })

        // this.$toast({
        //   component: ToastificationContent,
        //   toaster: 'b-toaster-bottom-right',
        //   props: {
        //     title: `Hoşgeldin ${userData.username}`,
        //     icon: 'CoffeeIcon',
        //     variant: 'success',
        //     text: `Başarılı bir şekilde giriş yapıldı.`,
        //   },
        // });




        // })
        // })
        // .catch(error => {
        // this.$refs.loginForm.setErrors(error.response.data.error)
        // })
      }
      else {

      }
    },
  },
  mounted: async function () {
    localStorage.removeItem('crmData');
    localStorage.removeItem('dashboardData');
    localStorage.removeItem('report_cache');
    localStorage.removeItem('agent_statisticts');
    localStorage.removeItem('records_cache');
    this.getRegion();
    let _url = window.location.href.split('://')[1].split('/')[0];
    await this.getCustomization(_url);
    if (window.location.search != '') {
      let q_string = Object.fromEntries(new URLSearchParams(window.location.search).entries());
      console.log(q_string);
      if (q_string['username']) {
        this.username = atob(q_string['username']);
        this.password = atob(q_string['password']);
        // globalThis.qm_token = q_string['t'];
        // let _usr = JSON.parse(atob(q_string['t'].split('.')[1]));
        // console.log(_usr);
        // this.username = `${_usr.username}@${_usr.domain}`;
        // globalThis.username = `${_usr.username}@${_usr.domain}`;
        // this.$http_in.defaults.headers.common['authorization'] = response.token;
        // this.$http_in.defaults.headers.common['username'] = this.username;

        // await this.GetUserProjectAndQueues();
        this.login();
      }
    } else {
      const _userData = JSON.parse(localStorage.getItem('userData'));
      if (![undefined, null].includes(_userData)) {
        this.username = _userData.username;
        this.password = _userData.password;
      }
    }


  }

}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.vs__selected-options {
  // max-height: 2.3rem; /* Adjust this value to suit your needs */
  max-height: 4.6rem; /* Adjust this value to suit your needs */
  overflow-y: auto;
}
.carousel-slide-background {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.verification-input {
  width: 4rem;
  height: 4rem;
}
</style>
